import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { width as twWidth } from '../../tailwind'
import { hidden } from '../styles/utils'

const Wrapper = styled.svg`
  ${tw`absolute`};
  stroke: currentColor;
  ${props => props.hiddenMobile && hidden};
  color: ${props => props.stroke};
  width: ${props => props.svgWidth};
  fill: ${props => props.fill};
  left: ${props => props.left};
  top: ${props => props.top};
`

const icons = {
  triangle: {
    shape: (
      <path d="M 56.667,0.667 C 25.372,0.667 0,26.036 0,57.332 c 0,31.295 25.372,56.666 56.667,56.666 31.295,0 56.666,-25.371 56.666,-56.666 0,-31.296 -25.372,-56.665 -56.666,-56.665 z m 0,106.055 c -8.904,0 -16.123,-5.948 -16.123,-13.283 H 72.79 c 0,7.334 -7.219,13.283 -16.123,13.283 z M 83.297,89.04 H 30.034 V 79.382 H 83.298 V 89.04 Z M 83.106,74.411 H 30.186 C 30.01,74.208 29.83,74.008 29.66,73.802 24.208,67.182 22.924,63.726 21.677,60.204 c -0.021,-0.116 6.611,1.355 11.314,2.413 0,0 2.42,0.56 5.958,1.205 -3.397,-3.982 -5.414,-9.044 -5.414,-14.218 0,-11.359 8.712,-21.285 5.569,-29.308 3.059,0.249 6.331,6.456 6.552,16.161 3.252,-4.494 4.613,-12.701 4.613,-17.733 0,-5.21 3.433,-11.262 6.867,-11.469 -3.061,5.045 0.793,9.37 4.219,20.099 1.285,4.03 1.121,10.812 2.113,15.113 C 63.797,33.534 65.333,20.5 71,16 c -2.5,5.667 0.37,12.758 2.333,16.167 3.167,5.5 5.087,9.667 5.087,17.548 0,5.284 -1.951,10.259 -5.242,14.148 3.742,-0.702 6.326,-1.335 6.326,-1.335 l 12.152,-2.371 c 10e-4,-10e-4 -1.765,7.261 -8.55,14.254 z"/>
    ),
    viewBox: '-3 0 120 115',
  },
  circle: {
    shape: (
      <path d="M82.168,73.668c7.994,0,14.5-6.506,14.5-14.5c0-5.105-2.732-9.841-7.072-12.44c0.049-0.543,0.072-1.056,0.072-1.561  c0-11.028-8.973-20-20-20c-1.963,0-3.887,0.283-5.746,0.841c-3.188-7.159-10.287-11.841-18.254-11.841c-11.028,0-20,8.972-20,20  c0,0.275,0.006,0.551,0.018,0.827c-1.165-0.218-2.341-0.327-3.518-0.327c-10.752,0-19.5,8.747-19.5,19.5  c0,10.752,8.748,19.5,19.5,19.5C22.581,73.668,81.736,73.668,82.168,73.668z M6.667,54.168c0-8.547,6.953-15.5,15.5-15.5  c1.736,0,3.475,0.304,5.166,0.902c0.5,0.209,0.993,0.439,1.469,0.716c2.96,1.725,5.077,4.634,5.81,7.981  c0.102,0.468,0.516,0.787,0.976,0.787c0.07,0,0.143-0.008,0.215-0.023c0.539-0.118,0.881-0.651,0.763-1.191  c-0.616-2.821-2.089-5.367-4.159-7.323c-0.307-0.323-0.854-0.729-1.543-1.847c-0.412-0.669-0.768-1.361-0.992-2.1  c-0.123-0.801-0.203-1.605-0.203-2.402c0-8.823,7.178-16,16-16c6.924,0,13.03,4.421,15.192,11.003  c0.174,0.529,0.561,0.962,1.066,1.194c0.51,0.232,1.09,0.242,1.602,0.028c1.953-0.813,4.02-1.225,6.141-1.225  c8.822,0,16,7.177,16,16c0,0.625-0.059,1.3-0.16,2.059c-2.447-0.57-5.027-0.501-7.465,0.255c-1.869,0.579-3.562,1.512-5.035,2.771  c-0.42,0.359-0.469,0.991-0.109,1.411c0.197,0.23,0.477,0.35,0.76,0.35c0.23,0,0.461-0.078,0.648-0.24  c1.266-1.081,2.723-1.881,4.328-2.38c2.611-0.81,5.408-0.698,7.938,0.249c0.01,0.006,0.018,0.015,0.027,0.02  c3.686,1.723,6.068,5.456,6.068,9.508c0,5.789-4.711,10.5-10.5,10.5c-0.354,0-59.684,0-60,0  C13.621,69.668,6.667,62.713,6.667,54.168z"></path>
    ),
    viewBox: '0 0 100 100',
  },
  arrowUp: {
    shape: (
      <>
        <path d="M28.74,20.81H1.26a1.26,1.26,0,0,1-1-2L14.16.5a1.25,1.25,0,0,1,1-.5h0a1.24,1.24,0,0,1,1,.51L29.75,18.8a1.25,1.25,0,0,1-1,2ZM3.81,18.29H26.22L15.16,3.37Z" />{' '}
        <path d="M28.74,42H1.26a1.28,1.28,0,0,1-1.13-.71A1.26,1.26,0,0,1,.26,40l13.9-18.29a1.28,1.28,0,0,1,1-.5h0a1.24,1.24,0,0,1,1,.51L29.75,40a1.26,1.26,0,0,1,.12,1.32A1.28,1.28,0,0,1,28.74,42ZM3.81,39.47H26.22L15.16,24.55Z" />
      </>
    ),
    viewBox: '0 0 30 42',
  },
  upDown: {
    shape: (
      <>
        <path d="M28.74,44.58a1.28,1.28,0,0,1-1-.51L15.16,27.13l-12.89,17a1.26,1.26,0,1,1-2-1.53l13.9-18.29a1.34,1.34,0,0,1,1-.5,1.24,1.24,0,0,1,1,.51L29.75,42.56a1.27,1.27,0,0,1-1,2Z" />
        <path d="M14.83,20.82h0a1.28,1.28,0,0,1-1-.52L.25,2a1.27,1.27,0,0,1,2-1.51L14.84,17.45,27.73.5a1.26,1.26,0,0,1,2,1.53L15.84,20.32A1.28,1.28,0,0,1,14.83,20.82Z" />
      </>
    ),
    viewBox: '0 0 30 44.58',
  },
  box: {
    shape: (
      <path d="M28,2V28H2V2H28m.13-2H1.88A1.88,1.88,0,0,0,0,1.88V28.13A1.88,1.88,0,0,0,1.88,30H28.13A1.87,1.87,0,0,0,30,28.13V1.88A1.88,1.88,0,0,0,28.13,0Z" />
    ),
    viewBox: '0 0 30 30',
  },
  hexa: {
    shape: (
      <polygon
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="27.5,21.904 15,28.809  2.5,21.904 2.5,8.095 15,1.19 27.5,8.095 "
      />
    ),
    viewBox: '0 0 30 30',
  },
}

const SVG = ({ stroke, fill, width, icon, left, top, hiddenMobile }) => (
  <Wrapper
    viewBox={icons[icon].viewBox}
    stroke={stroke}
    fill={fill}
    svgWidth={twWidth[`${width}`]}
    left={left}
    top={top}
    hiddenMobile={hiddenMobile}
  >
    {icons[icon].shape}
  </Wrapper>
)

export default SVG

SVG.propTypes = {
  stroke: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.number,
  icon: PropTypes.oneOf(['triangle', 'circle', 'arrowUp', 'upDown', 'box', 'hexa']).isRequired,
  left: PropTypes.string,
  top: PropTypes.string,
  hiddenMobile: PropTypes.bool,
}

SVG.defaultProps = {
  stroke: 'transparent',
  width: 8,
  fill: 'none',
  left: '0%',
  top: '0%',
  hiddenMobile: false,
}
