import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { Parallax } from 'react-spring/addons.cjs'

// Components
import Layout from '../components/Layout'
import ProjectCard from '../components/ProjectCard'
import ContactForm from '../components/ContactForm'

// Elements
import Inner from '../elements/Inner'
import { Title, BigTitle, Subtitle } from '../elements/Titles'

// Views
import Hero from '../views/Hero'
import Projects from '../views/Projects'
import About from '../views/About'
import Contact from '../views/Contact'

import avatar from '../images/avatar.jpg'

const ProjectsWrapper = styled.div`
  ${tw`flex flex-wrap justify-between mt-8`};
  display: grid;
  grid-gap: 4rem;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 1200px) {
    grid-gap: 3rem;
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-gap: 2rem;
  }
`

const Coming = styled.h1`
  color: white;
`

const AboutHero = styled.div`
  ${tw`flex flex-col lg:flex-row items-center mt-8`};
`

const Avatar = styled.img`
  ${tw`rounded-full w-32 xl:w-48 shadow-lg h-auto`};
`

const AboutSub = styled.span`
  ${tw`text-white pt-12 lg:pt-0 lg:pl-12 text-2xl lg:text-3xl xl:text-4xl`};
`

const AboutDesc = styled.p`
  ${tw`text-grey-light text-lg md:text-xl lg:text-2xl font-sans pt-6 md:pt-12 text-justify`};
`

const ContactText = styled.p`
  ${tw`text-grey-light font-sans text-xl md:text-2xl lg:text-3xl`};
`

const Footer = styled.footer`
  ${tw`text-center text-grey absolute pin-b p-6 font-sans text-md lg:text-lg`};
`

const Index = () => (
  <>
    <Layout />
    <Parallax pages={5}>
      <Hero>
        <BigTitle>
          HostedPrometheus
        </BigTitle>
        <Subtitle>The fastest path to worry-free, production-quality monitoring with fixed-price billing and no vendor lock-in</Subtitle>
      </Hero>
      <Projects>
        <Title>Features</Title>
        <ProjectsWrapper>
          <ProjectCard
            title="Fixed-price billing"
            bg="linear-gradient(to right, #0D63FF 0%, #0F5C20 100%)"
          >
            Your monthly bill will always be predictable as we don’t charge overages. Never worry about accidentally sending us too much data.
          </ProjectCard>
          <ProjectCard
            title="Retroactive recording rules"
            bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)"
          >
            Despair no more for forgetting to define a recording rule; define new rules and get instant results on historical data.
          </ProjectCard>
          <ProjectCard
            title="Firewall busting"
            bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)"
          >
            Don’t worry about opening ports into your network for us to scrape. As long as our agent is able to reach hostedprometheus.com, we’ll establish a secure reverse tunnel to your metric endpoints.
          </ProjectCard>
          <ProjectCard
            title="Open-source pledge"
            bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)"
          >
            We donate 10% of revenue to the development of the open-source Prometheus ecosystem. We benefit when this ecosystem grows and couldn't have made our product without the contributions of others.
          </ProjectCard>
          <ProjectCard
            title="Automatic alerting suggestions"
            bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)"
          >
            Don’t have an outage because you forgot to alert for a common error; hostedprometheus automatically detects well-known exporters and metric names and suggests alerts.
          </ProjectCard>
        </ProjectsWrapper>
      </Projects>
      <About>
        <Title>Pricing</Title>
        <AboutHero>
          <AboutSub>
            $10 / 10 Million Datapoints / month
          </AboutSub>
        </AboutHero>
      </About>
      <Contact>
        <Coming>Coming soon!</Coming>
      </Contact>
    </Parallax>
  </>
)

export default Index
